/**
 * Dev environment
 *
 * Put what you like in it, but DON'T use it to build prod !
 */
export const environment = {
  production: false,
  title: 'Weezchat, tchat illimité',
  api: 'https://chat-api-new-pp.contactdve.com/api',
  chat: 'https://pp-chat-liveengine.contactdve.com/',
  extCode: 'MA',
  key: 1,
  newsSectionUrl: 'https://m.weezchat.ma/news',
  footerText: null,
  footerLinks: [
    { value: 'Mentions légales', href: 'http://m.weezchat.ma/mentions_legales' },
    { value: 'Mon abonnement', href: 'http://www.mes-abonnements.ma/' }
  ],
  LP: 'http://promo.weezchat.ma/pwzchatma/lp_weetic/',
  redirectIfNoIframe: null,
  useBothSignUpMethod: true,
  avatarSize: 500,
  gtm: 'GTM-WNZF4G',
  showAllCities: true,
  completeProfileInputs: ['phoneNumber'],
  RTL: false,
  unsubscribeLink: 'http://www.mes-abonnements.ma/',
freeSubscription : false,
  home: {
    app: {
      available: true,
      linkGoogle: 'https://play.google.com/store/apps/details?id=com.virgoplay.tchat.weezchat&hl=fr',
      linkApple: '',
    },
    facebook: {
      available: true,
      link: 'https://www.facebook.com/WeezchatMA/'
    },
    twitter: {
      available: true,
      link: 'https://twitter.com/weezchat_ma?lang=fr'
    },
    instagram: {
      available: true,
      link: 'https://www.instagram.com/weezchat_maroc/'
    }
  },
  RGPD : {
    cookies: {
      available: true,
      link: 'https://www.dgp-legal.com/en/cookies-information/'
    }
  }
};
